function IsNewDisplay(IsNew){
  return (IsNew === true ? "New" : (IsNew === false) ? "Used" : "")
}
function IsNewUsedDisplay(IsNew){
  return (IsNew === true ? "New" : (IsNew === false) ? "Used" : "")
}
function IsCertifiedDisplay(IsCertified){
  return (IsCertified === true ? "Certified " : "" )
}

module.exports = {
  IsNewDisplay:(IsNew) => {
    return (IsNew === true ? "New" : (IsNew === false) ? "Used" : "")
  },
  configurableCompareString: (theString, compareVehicleInfo) => {
    let finalString = theString
    finalString = finalString.replace("{{compareModel}}", compareVehicleInfo.compareModel)
    finalString = finalString.replace("{{mainModel}}", compareVehicleInfo.mainModel)
    return finalString
  },
  configurableString: (theString, VehicleInfo) => {
    let finalString = theString
    finalString = finalString.split("{{new}}").join( IsNewDisplay(VehicleInfo.IsNew))
    finalString = finalString.split("{{new-lc}}").join( IsNewUsedDisplay(VehicleInfo.IsNew).toLowerCase())
    finalString = finalString.split("{{certified}}").join(IsCertifiedDisplay(VehicleInfo.IsCertified).split(' ').join(''))
    finalString = finalString.split("{{vin}}").join(  VehicleInfo.VIN)
    finalString = finalString.split("{{year}}").join( VehicleInfo.Year)
    finalString = finalString.split("{{make}}").join( VehicleInfo.Make)
    finalString = finalString.split("{{model}}").join( VehicleInfo.Model)
    finalString = finalString.split("{{trim}}").join( VehicleInfo.Trim)
    finalString = finalString.split("{{bodytype}}").join( VehicleInfo.BodyType)
    finalString = finalString.split("{{status}}").join( VehicleInfo.VehicleStatus)
    finalString = finalString.split("{{stock}}").join( VehicleInfo.StockNumber && VehicleInfo.StockNumber != "" ? VehicleInfo.StockNumber : "NA")
    finalString = finalString.split("{{engine}}").join( VehicleInfo.Engine && VehicleInfo.Engine != "" ? VehicleInfo.Engine  : "NA")
    finalString = finalString.split("{{transmission}}").join( VehicleInfo.Transmission && VehicleInfo.Transmission != "" ? VehicleInfo.Transmission : "NA")
    finalString = finalString.split("{{exterior}}").join( VehicleInfo.ExteriorColor && VehicleInfo.ExteriorColor != "" ? VehicleInfo.ExteriorColor : "NA")
    finalString = finalString.split("{{interior}}").join( VehicleInfo.InteriorColor && VehicleInfo.InteriorColor != "" ? VehicleInfo.InteriorColor : "NA")
    finalString = finalString.split("{{mileage}}").join( VehicleInfo.Mileage)
    return finalString
  },
  /*
  * remove all spaces " " and replace with dash "-"
  *
  * */
  configurableLink: (theLink, VehicleInfo) => {
    let finalLink = theLink
    try{
      finalLink = finalLink.replace("{{new}}", IsNewDisplay(VehicleInfo.IsNew))
      finalLink = finalLink.replace("{{vin}}", VehicleInfo.VIN)
      finalLink = finalLink.replace("{{year}}", VehicleInfo.Year)
      finalLink = finalLink.replace("{{make}}", VehicleInfo.Make.replace(" ", "-"))
      finalLink = finalLink.replace("{{model}}", VehicleInfo.Model.replace(" ", "-"))
      finalLink = finalLink.replace("{{bodytype}}", VehicleInfo.BodyType.replace(" ", "-"))
      finalLink = finalLink.replace("/-/", "/")
    }
    catch (e){
      console.log(VehicleInfo)
    }
    return finalLink
  },
  /*
  * Slug add / to the beginning of the final return
  *
  * */
  configurableSlug: (theSlug, VehicleInfo) => {
    let finalSlug = theSlug
    finalSlug = finalSlug.replace("{{new}}", IsNewDisplay(VehicleInfo.IsNew))
    finalSlug = finalSlug.replace("{{vin}}", VehicleInfo.VIN)
    finalSlug = finalSlug.replace("{{year}}", VehicleInfo.Year)
    finalSlug = finalSlug.replace("{{make}}", VehicleInfo.Make.replace(" ", "-"))
    finalSlug = finalSlug.replace("{{model}}", VehicleInfo.Model.replace(" ", "-"))
    finalSlug = finalSlug.replace("{{bodytype}}", VehicleInfo.BodyType.replace(" ", "-"))
    finalSlug = finalSlug.replace("/-/", "/")
    return "/" + (finalSlug == "" ? VehicleInfo.VIN : finalSlug)
  },
  /*
  * If you don't have the VehicleInfo data you can pass them in a indiviual items
  * */
  configurableSlugManual: (theSlug, VIN, Year, Make, Model, BodyType,IsNew) => {
    let finalSlug = theSlug
    finalSlug = finalSlug.replace("{{new}}", IsNewDisplay(IsNew))
    finalSlug = finalSlug.replace("{{vin}}", VIN)
    finalSlug = finalSlug.replace("{{year}}", Year)
    finalSlug = finalSlug.replace("{{make}}", Make.replace(" ", "-"))
    finalSlug = finalSlug.replace("{{model}}", Model.replace(" ", "-"))
    finalSlug = finalSlug.replace("{{bodytype}}", BodyType.replace(" ", "-"))
    finalSlug = finalSlug.replace("/-/", "/")
    return "/" + (finalSlug == "" ? VIN : finalSlug)
  }
}